import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import { ProductPrice } from "theme/components/atoms/Typography/Price";
import safeHtml from "web/core/utils/safeHtml";
import { useLocation } from "react-router";
import ProductLabel from "theme/modules/ProductView/ProductItem/ProductLabel";
import PointBleu from "theme/components/molecules/PointBleu";
import ProductItemActions from "./../ProductItemActions";

const ProductOverview = ({
  product,
  products,
  index,
  breadcrumb,
  enableProductAction,
  setShowAlertWishlist,
  disablePreload,
  recsUnitId,
}) => {
  const location = useLocation();
  const price_classname = classNames("product-overview__price", {
    "product-overview__price--pointbleu": product?.pointBleu?.isPointBleu,
  });

  return (
    <div className="product-overview">
      <div className="product-overview__name" itemProp="name">
        <TrackingLink
          eventName="Product Clicked"
          eventPayload={{
            sku: product.sku,
            name: `${product.name} ${product.product_color}`,
            id: product.entity_id,
            recsUnitId: recsUnitId,
          }}
          to={
            disablePreload
              ? product.path
              : {
                  pathname: product.path,
                  state: location
                    ? {
                        returnTo: `${location.pathname}${location.search}`,
                        products:
                          products &&
                          products.map(({ sku, path }) => ({ sku, path })),
                        selected: index,
                        breadcrumb: breadcrumb,
                      }
                    : { breadcrumb: breadcrumb },
                }
          }
          disablePreload={disablePreload}
          clickType="block"
        >
          <span
            className="product-overview__name--capitalize"
            dangerouslySetInnerHTML={{
              __html: safeHtml(`${product.name?.toLowerCase()}`),
            }}
          />
          <span
            className="product-overview__name-color"
            dangerouslySetInnerHTML={{
              __html: safeHtml(` ${product.product_color?.toLowerCase()}`),
            }}
          />
        </TrackingLink>
        <h3 className="product-overview__description">
          {product.shortDescription}
        </h3>
        {enableProductAction && (
          <div className="product-item__actions">
            <ProductItemActions
              product={product}
              setShowAlertWishlist={setShowAlertWishlist}
            />
          </div>
        )}
      </div>
      <div
        className={price_classname}
      >
        {product && (
          <>
            {!product?.isSpecialPriceExpired && (
              <ProductLabel product={product} />
            )}
            { product?.isPointBleu && <PointBleu asIcon /> }
            <ProductPrice
              prices={product.prices}
              item={product}
              isSpecialPriceExpired={product?.isSpecialPriceExpired}
              specialPrice={product?.specialPrice}
            />
          </>
        )}
      </div>
    </div>
  );
};

ProductOverview.propTypes = {
  product: PropTypes.object.isRequired,
  products: PropTypes.array,
  breadcrumb: PropTypes.array,
  index: PropTypes.number,
};

export default ProductOverview;
