import React from "react";

const Badges = ({ badges }) => {
  return (
    <div className="badges">
      {
        badges.map(({
          label,
          admin_label,
          css_class,
          image_url
        }, index) => (
        <div key={index}>
          <div className={`badge badge-${css_class}`}>
            {image_url && <img src={image_url} alt={label} />}
            {label}
          </div>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Badges;
