import React from "react";
import PropTypes from "prop-types";
import Image from "theme/components/atoms/Image";
import ProductOverview from "./Overview";
import classNames from "classnames";
import Badges from "theme/components/molecules/Badges";
import StorefrontContent from "theme/modules/StorefrontContent";

const ProductItem = (props) => {
  var computedClassName = Array.isArray(props.className)
    ? props.className.map((className) => `product-item--${className}`).join(" ")
    : `product-item--${props.className}`;

  return (
    <StorefrontContent type="Product" id={props.product.sku}>
    <div
      className={classNames("product-item", {
        [computedClassName]: props.className,
      })}
    >
      <div className="product-item__image">
        {props.product?.badges?.length > 0 && props.enableBadges && (
          <Badges badges={props.product.badges} />
        )}
        <Image
          itemProp="image"
          src={props.product.imageUrl}
          alt={props.product.name}
          format="medium"
          appearance="full"
          cover
          priority={props.imagePriority}
        />
      </div>

      <div className="product-item__overview">
        <ProductOverview
          product={props.product}
          products={props.products}
          breadcrumb={props.breadcrumb}
          location={props.location}
          index={props.index}
          enableProductAction={props.EnableProductAction}
          setShowAlertList={props.setShowAlertList}
          disablePreload={props.disablePreload}
          recsUnitId={props.recsUnitId}
        />
      </div>
    </div>
    </StorefrontContent>
  );
};

ProductItem.propTypes = {
  categoryName: PropTypes.string,
};

export default ProductItem;
