import React from "react";
import SmartCmsBlock from "theme/modules/SmartCmsBlock/SmartCmsBlock";

const PointBleu = ({ asIcon }) => {

  return !asIcon ? (
    <div className="pointbleu--message">
      <span className="pointbleu-icon"/>
        <SmartCmsBlock identifier={'pointbleu'} />
    </div>
  ) : (
    <span className="pointbleu-icon"/>
  );
};

export default PointBleu;
